<template>
  <div>
    <div class="flex md12">
      <va-card title="Create User">
        <div class="d-flex flex-center">
          <div class="flex md4 sm6 xs12">
            <form @submit.prevent="onsubmit">
              <va-input
                v-model="email"
                placeholder="User Email"
              >
                <va-icon
                  slot="prepend"
                  color="gray"
                  name="fa fa-envelope-o"
                />
              </va-input>
              <va-input
                v-model="password"
                type="password"
                placeholder="Password (At least 6 characters)"
              >
                <va-icon
                  slot="prepend"
                  color="gray"
                  name="fa fa-lock"
                />
              </va-input>
              <va-input v-model="phoneNumber" @input="maskPhone" maxlength="12" placeholder="Phone Number">
                  <va-icon slot="prepend" color="gray" name="fa fa-phone" />
              </va-input>
              <va-input
                v-model="name"
                placeholder="Full Name"
              />
              <va-select
                v-model="credential"
                searchable
                :options="credentialOptions"
                text-by="displayName"
                key-by="displayName"
                placeholder="Credential"
              />

              <va-select
                v-model="country"
                @input="onCountryChange"
                :options="countryOptions"
                text-by="name"
                key-by="name"
                placeholder="i.e United States"
              />

              <va-select
                class="custom-dropdown-size"
                v-model="state"
                v-if="stateIsListed"
                :options="stateOptions"
                text-by="name"
                key-by="abbreviation"
                :disabled="country.name !== 'United States'"
                placeholder="i.e Florida"
              />
              <va-input
                v-model="state"
                v-else
                placeholder="i.e Florida"
              />


              <va-input
                v-if="cityIsListed"
                v-model="city"
                placeholder="i.e Miami"
              />
              <va-input
                v-else
                v-model="city"
                placeholder="i.e Miami"
              />


              <div v-if="this.state.abbreviation == 'FL'">
                <div class="dropDown">
                  <div>License Credential</div>
                  <va-select
                    v-model="ce_credential_id"
                    text-by="name"
                    key-by="code"
                    :options="CEBrokerOptions"
                  />
                </div>
                <div class="text mt-2">
                  <div>License Number</div>
                  <va-input
                    class="appearance-none block w-full  text-gray-700 border
                    border-gray-300 rounded py-2 px-1
                    focus:outline-none focus:bg-white focus:border-gray-500"
                    v-model="ce_license_number"
                    type="text"
                    maxlength="15"
                    placeholder="xxxxxxxxxxxxxxxx"
                  />
                </div>
              </div>
              <va-toggle
                v-model="developer"
                label="Developer"
              />
              <va-toggle
                v-model="freeSubscription"
                label="Free Subscription"
              />
              <va-toggle
                v-model="demoAccess"
                label="Demo Access"
              />
              <div class="flex flex-center">
                <div class="flex md6 sm8 xs12">
                  <va-button
                    @click="createUser"
                    v-if="!loading"
                    :disabled="!allRequirementsMet"
                  >
                    <i
                      class="va-icon fa fa-user-plus"
                      style="margin-right: 1em;"
                    />  Create User
                  </va-button>
                  <va-button v-else>
                    <semipolar-spinner
                      :color="'white'"
                    />
                  </va-button>
                </div>
              </div>
            </form>
            <ul v-if="users.length">
              <li
                v-for="user in users"
                :key="user.uid"
              >
                {{ user.displayName }} || {{ user.email }} || <i class="va-icon fa fa-mail" /> || Edit || <button @click="createUser">
                  Reset Password
                </button>
              </li>
            </ul>
          </div>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>

import {SemipolarSpinner} from 'epic-spinners';
import firebase from 'firebase';
import moment from 'moment';
import axios from '@/scripts/interceptor.js'
export default {
  name: 'CreateUser',
  data() {
    return {
      email: '',
      phoneNumber: '',
      country: '',
      city: '',
      state: '',
      password: '',
      ce_credential_id: '',
      ce_license_number: '',
      credential: '',
      name: '',
      nameKey: 'name',
      freeSubscription: false,
      developer: false,
      demoAccess: false,
      loading: false,
      countryOptions: [],
      stateOptions: [],
      CEBrokerOptions: [],
      credentialOptions: [],
      emailErrors: [],
      users: [],
      cityIsListed: true,
      stateIsListed: true,
    };
  },
  async created() {
    await this.getCountryOptions();
    await this.getStateOptions();
    this.getCEBrokerOptions();
    this.getCredentialOptions();
    this.onCountryChange();
  },
  components: {
    SemipolarSpinner,
  },
  computed: {
    allRequirementsMet() {
      let requirementsMet = false;
      if (this.email && this.name && this.password.length >= 6 && this.credential && this.country && this.phoneNumber) {
        if (this.country.name === 'United States' && this.state && this.city) {
          requirementsMet = true;
          if (this.state.abbreviation == 'FL') {
            if (this.ce_credential_id && this.ce_license_number) {
              requirementsMet = true;
            } else {
              requirementsMet = false;
            }
          }
        } else if (this.country.name !== 'United States') {
          requirementsMet = true;
        } else {
          requirementsMet = false;
        }
      }
      return requirementsMet;
    },
  },
  methods: {
    maskPhone() {
      this.phoneNumber = this.phoneNumber.replace(/[^0-9]/g, '');
      if (this.phoneNumber.length > 3 && this.phoneNumber.length <= 6) {
        this.phoneNumber = `${this.phoneNumber.slice(0, 3)}-${this.phoneNumber.slice(3)}`;
      } else if (this.phoneNumber.length > 6) {
        this.phoneNumber = `${this.phoneNumber.slice(0, 3)}-${this.phoneNumber.slice(3, 6)}-${this.phoneNumber.slice(6)}`;
      }
    },
    onsubmit() {
      return false;
    },
    onCountryChange() {
      this.stateIsListed = this.cityIsListed = this.country.name == "United States"; 
      this.state = '';
    },
    getCEBrokerOptions(option = true) {
      this.CEBrokerOptions = [];
      // get from CeBrokerCourseCredentials only if 'active' is true and order by 'longname'
      this.$db.collection('CeBrokerCourseCredentials').where('active', '==', option).get().then((snapshot) => {
        snapshot.forEach((doc) => {
          const cData = doc.data();
          if (!this.ce_license_name) {
            this.ce_license_name = (cData.id == this.ce_credential_id) ? cData.longname : '';
          }
          this.CEBrokerOptions.push({name: cData.longname, code: cData.id});
        });
      });
    },
    getCredentialOptions(option = true) {
      this.$db.collection('credentials').where('active', '==', option).get().then((snapshot) => {
        snapshot.forEach((doc) => {
          this.credentialOptions.push(doc.data());
        });
      });
    },
    async getCountryOptions() {
      await this.$db.collection('countries').orderBy('name').get().then((snapshot) => {
        snapshot.forEach((doc) => {
          this.countryOptions.push(doc.data());
        });
      });
      //move option united states to the top of the array
      let unitedStates = this.countryOptions.find((country) => country.name == 'United States');
      if(unitedStates){
        this.countryOptions = this.countryOptions.filter((country) => country.name != 'United States');
        this.countryOptions.unshift(unitedStates);
      }
    },
    async getStateOptions() {
      await this.$db.collection('states').orderBy('name').get().then((snapshot) => {
        snapshot.forEach((doc) => {
          this.stateOptions.push(doc.data());
        });
      });
    },
    /**
     * @version 1.2
     * @description This function creates a user in firebase auth and then creates a user in the database
     * @endpoint admin-createUser
     */
    async createUser() {
      const userData = {
        email: this.email.toLowerCase() || '',
        phoneNumber: this.phoneNumber ? this.phoneNumber : '',
        password: this.password,
        displayName: this.name,

        credentialCode: this.credential.value,
        countryCode: this.country.name,
        stateCode: this.state.length > 3 ? this.state :  this.state.abbreviation,
        cityCode: this.city,

        photoURL: 'https://nihss.plus/assets/defaultProfilePic.png',
        provider: 'password',

        ...(this.state.abbreviation == 'FL' && {ce_credential_id: this.ce_credential_id}),
        ...(this.state.abbreviation == 'FL' && {ce_license_number: this.ce_license_number}),

        ...(this.freeSubscription.toString() == 'true' && {freeSubscription: this.freeSubscription}),
        ...(this.developer.toString() == 'true' && {developer: this.developer}),
        ...(this.demoAccess.toString() == 'true' && {demoAccess: this.demoAccess}),
      };
      this.loading = true;
      await axios.post(window.firebaseURL + `api/admin/createUser`, userData).then((result) => {
        // reset form
        this.email = '';
        this.phoneNumber = '';
        this.password = '';
        this.name = '';
        this.credential = '';
        this.country = '';
        this.state = '';
        this.city = '';
        this.ce_credential_id = '';
        this.ce_license_number = '';
        this.freeSubscription = false;
        this.developer = false;
        this.demoAccess = false;
        // show success message
        this.$swal.fire({
          title: 'User Created',
          icon: 'success',
          text: 'User successfully created!',
        });
        this.loading = false;
      }).catch((error) => {
        this.$swal.fire({
          title: 'Oops...',
          icon: 'error',
          text: error?.response?.data?.message || error.message,
        });
        this.loading = false;
      });
    },
  },
};
</script>

<style>
.custom-dropdown-size .va-dropdown__content {
  height: 35vh !important;
}
</style>